// navbar

.navbar {
  padding: 15px 0;
}
.navbar-brand {
  font-size: 1.5rem;
}
.navbar-toggler {
  border: none;
  padding: 0;
  font-size: 16px;
  &:focus {
    box-shadow: none;
  }
}
.navbar-nav {
  .nav-item {
    border-bottom: 1px solid rgba($black, 0.1);

    .nav-link {
      padding: 0.8rem 0;
    }
  }
  &:last-child {
    .nav-item:last-child {
      border: none;

      .nav-link {
        padding-right: 0;
      }
    }
  }
}

/* light mode */
.navbar-light {
  border-bottom: 1px solid rgba($black, 0.15);

  .navbar-toggler {
    color: rgba($black, 1);
    &:hover {
      color: rgba($black, 0.8);
    }
  }
}

/* dark mode */
.navbar-dark {
  .navbar-toggler {
    color: rgba($white, 1);
    &:hover {
      color: rgba($white, 0.8);
    }
  }
}
.navbar-dark {
  .nav-item {
    border-bottom: 1px solid rgba($white, 0.1);
  }
}

@include media-breakpoint-up(md) {
  .navbar-nav {
    .nav-item {
      border-bottom: none;

      .nav-link {
        padding: 0.5rem;
      }
    }
  }
}
