// dropdown

.dropdown-menu {
  top: calc(100% + 8px);
  margin: 0;
  border: none;
  border-radius: $border-radius;
  box-shadow: none;
  background-color: transparent;
  border-radius: $border-radius-xl;
}

/* light - default */
.dropdown-item {
  padding: 0.25rem 0.5rem;
  color: rgba($black, 0.55);
}
.dropdown-item:hover,
.dropdown-item:focus {
  background: transparent;
  color: rgba($black, 0.7);
}
.dropdown-item.active,
.dropdown-item:active {
  background: transparent;
  color: rgba($black, 0.9);
}

.dropdown-divider {
  margin-left: 0.5rem;
  border-top-color: rgba($black, 0.1);
}
.dropdown-toggle::after {
  border: solid;
  border-width: 0 2px 2px 0;
  padding: 3px;
  transform: rotate(45deg);
  float: right;
  margin: 6px 5px 0 10px;
}
.dropdown-toggle.show::after {
  transform: rotate(225deg);
  margin-top: 11px;
}

/* dark */
@include media-breakpoint-down(md) {
  .navbar-dark .dropdown-item {
    color: rgba($white, 0.55);
  }
  .navbar-dark .dropdown-item:hover,
  .navbar-dark .dropdown-item:focus {
    color: rgba($white, 0.7);
  }
  .navbar-dark .dropdown-item.active,
  .navbar-dark .dropdown-item:active {
    background: transparent;
    color: rgba($white, 0.9);
  }
  .navbar-dark .dropdown-divider {
    border-top-color: rgba($white, 0.1);
  }
}

@include media-breakpoint-up(md) {
  .dropdown-menu {
    // box-shadow: 0 0.25rem 0.5rem rgba($black, 0.15);
    background-color: $white;
    top: calc(100% + 13px) !important;
    border: 1px solid rgba($black, 0.15);
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .dropdown-item {
    padding: 0.25rem 1.5rem;
  }
  .dropdown-divider {
    margin-left: initial;
  }

  /* light mode */
  .navbar-light .dropdown-menu {
    top: calc(100% + 14px) !important;
  }
}
