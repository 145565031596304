// google font
@import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900|Raleway:400,300,600,650,700');

// custom variable
@import 'custom/variables';

// boostrap
@import 'node_modules/bootstrap/scss/bootstrap';

// custom components
@import 'custom/button';
@import 'custom/card';
@import 'custom/container';
@import 'custom/dropdown';
@import 'custom/modal';
@import 'custom/navbar';

// new components
@import 'new/general';
@import 'new/hamburger';
@import 'new/bootstrap-icons';
@import 'new/paging';
