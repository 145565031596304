// paging

.paging {
  .page::before,
  .page::after {
    font-family: 'bootstrap-icons';
    content: '';
    font-weight: 300;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    position: relative;
    top: 3px;
  }
  .page-prev::before {
    content: '\f284';
    margin-right: 5px;
  }
  .page-next::after {
    content: '\f285';
    margin-left: 5px;
  }
  .page-disabled {
    pointer-events: none;
    opacity: 0.3;
  }
  .page:hover,
  .page:focus {
    color: $primary !important;
  }
}

@include media-breakpoint-down(md) {
  .paging {
    .page {
      border: 1px solid rgba($black, 0.15);
      border-radius: 5px;
      background: $white;
    }
    .page-next {
      padding: 5px 8px 7px 11px;
    }
    .page-prev {
      padding: 5px 11px 7px 8px;
    }
    .page-disabled {
      opacity: 1;
      color: rgba($black, 0.15) !important;
    }
    .page:hover,
    .page:focus {
      color: rgba($black, 0.7) !important;
    }
  }
}
