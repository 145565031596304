/* basic styles */

.skeleton {
    background-color: #ddd;
    border-radius: 4px;
}
.skeleton.title {
    width: 200px;
    height: 20px;
    opacity: .5;
    border-radius: .6rem;
}
.skeleton.price {
    width: 28.8px;
    height: 28.8px;
    margin-left: auto;
    margin-right: auto;
    opacity: 1;
    border-radius: 50%;
}
.skeleton-wrapper {
    margin: 0;
    padding: 0;
    position: relative;
    overflow: hidden;
    border-radius: 0;
    background-color: transparent;
}

/* animation effects */
.shimmer-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    animation: loading 2.5s infinite;
}
.shimmer {
    width: 50%;
    height: 100%;
    background-color:rgba(255, 255, 255, .2);
    transform: skewX(-20deg);
    box-shadow: 0 0 30px 30px rgba(255, 255, 255, .05);
}
@keyframes loading {
    0% {transform: translateX(-150%);}
    50% { transform: translateX(-60%);}
    100% {transform: translateX(150%);}
}