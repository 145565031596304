// container

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}
@include media-breakpoint-up(sm) {
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    padding-right: 15px;
    padding-left: 15px;
  }
}

// vertical container
.container-vertical {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 220px);

  .container-vertical-content {
    flex-grow: 1;
  }
}
