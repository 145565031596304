// card

.card-header {
  border-bottom: 1px solid rgba($black, 0.1);
  padding: 1.5rem 0 1rem 0;
  font-size: 1.7rem;
  width: calc(100% - 3.4rem);
  margin: 0 auto 20px auto;
}
.card-body {
  padding: 1rem 1.7rem;
}
.card-mark {
  position: absolute;
  background-color: rgba($primary, 0.6);
  top: 0;
  left: 0;
  width: 7px;
  height: 100%;
  border-top-left-radius: 0.6rem;
  border-bottom-left-radius: 0.6rem;
  border-left: 1px solid transparent;
}
.card-action {
  position: absolute;
  background-color: rgba($primary, 0.6);
  color: rgba($white, 1);
  right: 0;
  width: 24px;
  // height: 50%;
  height: 24px;
  padding: 0 6px;
  border-right: 1px solid transparent;
  cursor: pointer;

  &::after {
    font-family: 'bootstrap-icons';
    content: '';
    position: absolute;
    line-height: 0;
  }
}
.card-close {
  background-color: rgba($primary, 0.6);
  top: 0;
  border-top-right-radius: 0.6rem;
  border-top: 1px solid transparent;
  // font-size: 17px;
  font-size: 11px;
  font-weight: 300;

  &::after {
    content: '\f659';
    top: 11px;
    right: 6px;

    // content: '\f5de';
    // top: 13px;
    // right: 5px;
  }
}
.card-edit {
  background-color: rgba($secondary, 0.6);
  color: rgba($black, 0.6);
  bottom: 0;
  border-bottom-right-radius: 0.6rem;
  border-bottom: 1px solid transparent;
  font-size: 12px;

  &::after {
    content: '\f4c8';
    // content: '\f479';
    bottom: 13px;
  }
}
.card-transaction:hover h4,
.card-transaction:focus h4 {
  font-weight: 500 !important;
}
.card-transaction:hover .card-mark,
.card-transaction:focus .card-mark {
  background-color: rgba($primary, 1);
}
.card-transaction:hover .card-close,
.card-transaction:focus .card-close {
  background-color: rgba($danger, 1);
}
.card-transaction:hover .card-edit,
.card-transaction:focus .card-edit {
  background-color: rgba($secondary, 1);
}
.card-close:hover,
.card-close:focus {
  background-color: rgba($danger, 1);
}
.card-edit:hover,
.card-edit:focus {
  background-color: rgba($secondary, 1);
  color: rgba($black, 0.6);
}

@include media-breakpoint-down(xl) {
  .card-header.card-header-lg {
    font-size: 1.5rem;
  }
}
