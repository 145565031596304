// modal

.modal-header {
  padding: 0.9rem 1.5rem;
  border-bottom: 1px solid #f1f1f1;

  .btn-close {
    font-weight: $font-weight-lighter;
    font-size: 0.9rem;

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
}
.modal-title {
  margin-top: 2px;
}
.modal-footer {
  padding: 0.6rem 1.25rem;
  border-top: 1px solid #f1f1f1;
}
.modal-content {
  border: 0;
  box-shadow: none;
}

@include media-breakpoint-down(sm) {
  // .modal-dialog {
  //   margin: 1.25rem;
  // }
}
