body {
  background-color: $gray-200;
}

hr {
  color: transparent;
  opacity: 1;
  border-top: 1px solid rgba($black, 0.1);
}

a {
  text-decoration: none;
}
.a-dotted {
  border-bottom: 1px dotted;
  color: $primary;
  text-decoration: none;
}

.required {
  font-size: 80%;
  font-weight: 300;
  color: $gray-600;
}

.disclaimer {
  font-size: 90%;
  border-top: 1px solid rgba($black, 0.1);
  padding-top: 1rem;
  margin-top: 100px;
  margin-bottom: 80px;
}
.disclaimer .badge {
  background: $gray-600;
  color: rgba($white, 1);
  border-radius: 20px;
  padding: 0.32rem 0.65rem 0.32rem 0.65rem;
  line-height: 12px;
  text-transform: lowercase;
}

/* form */
.form-control:focus,
.form-check-input:focus {
  border-width: 2px !important;
  box-shadow: none !important;
}
.form-control.is-invalid,
.form-control.is-valid {
  background-image: none !important;
}
.show-password-icon {
  position: absolute;
  right: 1rem;
  top: 0.675rem;
  font-size: 1.25rem;
  cursor: pointer;
  filter: invert(15%);
}
.form-currency {
  position: relative;

  input {
    padding-right: 35px;
  }

  &::before {
    position: absolute;
    font-family: 'bootstrap-icons';
    content: '\f636';
    top: 9px;
    right: 8px;
    width: 20px;
    height: 20px;
    font-size: 21px;
    color: rgba(0, 0, 0, 0.3);
  }
}

/* Toastify */
.Toastify__close-button {
  margin-top: 0 !important;
}
.Toastify__toast-container {
  font-size: 16px !important;
  font-weight: 300 !important;
  width: 100% !important;
  min-width: 320px !important;
}
.Toastify__toast-theme--dark {
  background: #333 !important;
}
@media (min-width: 576px) {
  .Toastify__toast-container {
    width: calc(100% - 40px) !important;
  }
}
@media (min-width: 768px) {
  .Toastify__toast-container {
    width: auto !important;
  }
}

/* profile */
.profile-login-icon {
  width: 30px;
  height: 30px;
  position: relative;
  top: -2px;
  right: 0;
  margin-right: 8px;
  margin-left: 0;
  border-radius: 50%;
}

/* spinner */
.spinner-form-button {
  position: relative;
  width: 20px;
  height: 20px;
  border-width: 2px;
  margin-right: 8px;
  top: 1px;
}

/* font */
.fw-medium {
  font-weight: 500;
}
.fw-semibold {
  font-weight: 600;
}
