// custom variables

// colors
$primary: #8a39e1;
$primary: #1c6dd0;
$primary: #185adb;
$secondary: #e9ecef;
$gray-100: #fafafa;
$danger: #e74c3c;
$success: #07bc0c;
$warning: #f1c40f;
$info: #3498db;

// contrast
$min-contrast-ratio: 2.3;

// import the functions and variables
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';

// custom color
$custom-colors: (
  'tertiary': #1db9c3
);
$theme-colors: map-merge($theme-colors, $custom-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop(
  $utilities-colors,
  rgba-css-var,
  '$key',
  'text'
);
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, '$key', 'bg');

// typography
$font-family-sans-serif: 'Poppins', 'Raleway', 'Arial', sans-serif;

// border radius
$border-radius-xl: 0.6rem;

// button
$btn-border-width: 1px;
$btn-padding-x-lg: 1.3rem;

// card
$card-border-color: rgba($black, 0.15);
$card-border-radius: $border-radius-xl;
$card-cap-bg: rgba($black, 0);

// form
$input-border-color: rgba($black, 0.15);
$form-feedback-icon-valid: 0;
$form-feedback-icon-invalid: 0;
$form-check-input-focus-box-shadow: 0;
$form-select-focus-box-shadow: 0;

// focus shadow (disable)
$btn-focus-width: 0;
$input-focus-box-shadow: none;
$input-focus-border-color: $primary;

// Modal
$modal-header-border-width: 0;
$modal-inner-padding: 1.5rem;
$modal-header-padding-y: 1.2rem;
$modal-header-padding-x: 1.5rem;
$modal-content-border-radius: $border-radius-xl;
